import SectionHeader from '../components/sections/header';
import SectionAffiliateProgramRules from '../components/sections/affiliate-program-rules';
import SectionFooter from '../components/sections/footer';

import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import { IPage } from '../models/page.model';
import { useUser } from '../hooks/use-user';
import { useHasPermission } from '../hooks/use-has-permission';
import { usePagePathname } from '../hooks/use-page-pathname';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import UserLoading from '../components/sections/user-loading';

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const User: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;
    const { mainSectionIndex } = getPageConfigFromSections(page.sections);
    const sections = getSectionsWithRepeatIndex(page.sections);
    const user = useUser();
    const isAffiliateMember = useHasPermission('AffiliateMember');
    const isRulesAccepted = useHasPermission('AffiliatePanelAccess');
    const paths = usePagePathname();

    useEffect(() => {
        if (user.isLoggedIn) return;
        navigate(paths.login || '/', { replace: true });
    }, [paths.login, user.isLoggedIn]);

    useEffect(() => {
        if (!user.data) return;
        if (!isAffiliateMember) {
            navigate('/', { replace: true });
            return;
        }
        if (!isRulesAccepted) {
            navigate(paths.affiliateProgramRules || '/');
            return;
        }
    }, [isAffiliateMember, isRulesAccepted, paths.affiliateProgramRules, user.data]);

    if (!user.isLoggedIn || !user.data) {
        return <UserLoading />;
    }

    return (
        <MainLayout>
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default User;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'affiliate-program-rules': SectionAffiliateProgramRules,
    'footer': SectionFooter,
};