import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import { useConfirmRulesMutation, useCreatePupilMutation } from '../redux/api/affiliate';

export const useAffiliate = () => {
    const t = useTranslations('RTKQueryApi');

    const [createPupil, createPupilMutation] = useCreatePupilMutation();
    const [confirmRules, confirmRulesMutation] = useConfirmRulesMutation();

    const createPupilErrors = useMemo(() => {
        return getRtkQueryErrors(createPupilMutation.error, t.errors);
    }, [createPupilMutation.error, t.errors]);
    const confirmRulesErrors = useMemo(() => {
        return getRtkQueryErrors(confirmRulesMutation.error, t.errors);
    }, [confirmRulesMutation.error, t.errors]);

    return {
        createPupil: {
            fetch: createPupil,
            data: createPupilMutation.data,
            isLoading: createPupilMutation.isLoading,
            isSuccess: createPupilMutation.isSuccess,
            isError: createPupilMutation.isError,
            errors: createPupilErrors,
        },
        confirmRules: {
            fetch: confirmRules,
            data: confirmRulesMutation.data,
            isLoading: confirmRulesMutation.isLoading,
            isSuccess: confirmRulesMutation.isSuccess,
            isError: confirmRulesMutation.isError,
            errors: confirmRulesErrors,
        },
    };
};
