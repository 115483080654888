import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { container, title, description, form } from './affiliate-program-rules.module.scss';

import { ESharedFileRelation } from '../../models/shared-file.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { affiliateRulesForm } from '../../formik/affiliate-rules';
import { useSharedFile } from '../../hooks/use-shared-file';
import { useAffiliate } from '../../hooks/use-affiliate';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useHasPermission } from '../../hooks/use-has-permission';
import { useUser } from '../../hooks/use-user';
import useTranslations from '../../hooks/use-translations';

import UserLayout from '../../layouts/user-layout';
import FormGenerator from '../molecules/form-generator';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import Markdown from '../hoc/markdown';

export interface IUserDataSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IUserDataProps {
    className?: string;
    section: IUserDataSection;
}

const AffiliateProgramRules = ({ section, className }: IUserDataProps) => {
    const t = useTranslations('AffiliateProgramRules');
    const { sectionId, css, style } = section;
    const { links = [] } = section.items || {};
    const rulesUrl = useSharedFile(ESharedFileRelation.affiliateRegulations)?.pathname || '';
    const { fetch, errors, isSuccess, isLoading } = useAffiliate().confirmRules;
    const { formikRef, globalErrorMessage, otherErrors } = useRtkQueryFormError(errors);
    const pathNames = usePagePathname();
    const userState = useUser();
    const isRulesAccepted = useHasPermission('AffiliatePanelAccess');

    useEffect(() => {
        if (isSuccess || (userState.data && isRulesAccepted)) {
            // await userState.get.refetch().unwrap

            // URL BO POTRZEBUJEMY REFETCHA NA USERZE
            if (typeof window !== 'undefined') {
                window.location.href = window.location.origin + pathNames.affiliateProgramDashboard;
            }

            navigate(
                pathNames.affiliateProgramDashboard ? pathNames.affiliateProgramDashboard : '/'
            );
        }
    }, [isSuccess, userState.data, isRulesAccepted, pathNames.affiliateProgramDashboard]);

    return (
        <UserLayout
            sectionId={sectionId}
            className={`${className} ${container}`}
            links={links}
            css={css}
            style={style}
            header={t.header}
        >
            <p className={title}>{t.title}</p>
            <Markdown className={description}>{t.description}</Markdown>
            <FormGenerator
                formikProps={{ onSubmit: () => fetch({}) }}
                name={'AffiliateProgramRules'}
                fields={affiliateRulesForm}
                extraFieldsProps={{ rulesUrl: rulesUrl }}
                className={form}
                formRef={formikRef}
                errorMessage={globalErrorMessage}
                otherErrors={otherErrors}
                submitLoading={isLoading}
            />
        </UserLayout>
    );
};

export default AffiliateProgramRules;
